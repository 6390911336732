<template>
  <div id="game-left-area">
    <TabDefault />

    <div v-if="datas.length==0">
      <b-row class="league-area p-0 m-0" v-if="i == 0 || (i > 0 && item.league != datas[i-1].league)">
        <b-col class="d-flex center b-first p-0 m-0 col-1 col-sm-2"><b-img class="country-img" :src="makeLeagueImg(item)"></b-img></b-col>
        <b-col class="d-flex ta-left league-title p-0 m-0 col-4 col-sm-4">{{ (item.league_code && item.league_code != undefined)?item.league_code:item.league }}</b-col>
        <b-col class="p-0 m-0 col-7 col-sm-6">
          <b-row class="m-0 p-0">
            <b-col cols="3" class="d-flex center">Home</b-col>
            <b-col cols="3" class="d-flex center">Draw</b-col>
            <b-col cols="3" class="d-flex center">Away</b-col>
            <b-col cols="3" class="d-flex center">More</b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="league-area py-5 m-0">
        <b-col class="col-12 ta-center">등록된 경기가 존재하지 않습니다</b-col>
      </b-row>
    </div>

    <div :key="i" v-for="(item, i) in datas" class="pr-1">
      <b-row class="league-area p-0 m-0" v-if="i == 0 || (i > 0 && item.league != datas[i-1].league)">
        <!--
        <b-col class="d-flex center b-first p-0 m-0 col-1 col-sm-2"><b-img class="country-img" :src="makeLeagueImg(item)"></b-img></b-col>
        <b-col class="d-flex ta-left league-title p-0 m-0 col-4 col-sm-4">{{ (item.league_code)?item.league_code:item.league }}</b-col>
        -->
        <b-col class="d-flex left b-first p-0 m-0 col-5 col-sm-5 league-name">
          <b-img class="country-img ml-1" :src="makeLeagueImg(item)"></b-img>
          <span class="ml-h">{{ (item.league_code)?item.league_code:item.league }}</span>
        </b-col>
        <b-col class="p-0 m-0 col-7 col-sm-7">
          <b-row class="m-0 p-0">
            <b-col cols="3" class="d-flex center">Home</b-col>
            <b-col cols="3" class="d-flex center">Draw</b-col>
            <b-col cols="3" class="d-flex center">Away</b-col>
            <b-col cols="3" class="d-flex center">More</b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="game-list-data p-0 m-0" id="game_list">
        <b-col class="col-5 col-sm-5 left b-first m-0 p-0">
          <div class="m-0 p-0 team-name ta-center">
            <span class="ml-1">{{ getDay(item.time) }}</span>
            <br>
            <span class="ml-1">{{ getTime(item.time) }}</span>
          </div>
          <div class="m-0 p-0 team-name">
            <span class="ml-h" v-if="item.sports_id == 1 || item.sports_id == 91 || item.sports_id == 162 || ( item.sports_id == 18 && item.reserved == 0 )">
              <b-img :src="makeTeamImg(item.home_img_id, 1)" class="team-img test-img"></b-img>&nbsp;&nbsp;<span class="team-name">{{ item.home_ko }}</span>
            </span>
            <span class="ml-h" v-if="item.sports_id == 16 || item.sports_id == 17 || ( item.sports_id == 18 && item.reserved == 1 )">
              <b-img :src="makeTeamImg(item.away_img_id, 1)" class="team-img test-img"></b-img>&nbsp;&nbsp;<span class="team-name">{{ item.away_ko }}</span>
            </span>
            <br>
            <span class="ml-h" v-if="item.sports_id == 1 || item.sports_id == 91 || item.sports_id == 162 || ( item.sports_id == 18 && item.reserved == 0 )">
              <b-img :src="makeTeamImg(item.away_img_id, 2)" class="team-img test-img"></b-img>&nbsp;&nbsp;<span class="team-name">{{ item.away_ko }}</span>
            </span>
            <span class="ml-h" v-if="item.sports_id == 16 || item.sports_id == 17 || ( item.sports_id == 18 && item.reserved == 1 )">
              <b-img :src="makeTeamImg(item.home_img_id, 2)" class="team-img test-img"></b-img>&nbsp;&nbsp;<span class="team-name">{{ item.home_ko }}</span>
            </span>
          </div>              
        </b-col>

        <b-col class="col-7 col-sm-7 m-0 p-0" style="width:100%;">
          <b-row class="m-0 p-0" style="width:100%;" v-if="item.sports_id == 1" title="축구">
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[0].id" v-on:click="goCart(item, 0, $event)">{{ item.smp[0].odds }}</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[1].id" v-on:click="goCart(item, 1, $event)">{{ item.smp[1].odds }}</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[2].id" v-on:click="goCart(item, 2, $event)">{{ item.smp[2].odds }}</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate bg2" v-on:click="gameView(item)">+{{ item.cnt }}</b-col>
          </b-row>
          <b-row class="m-0 p-0" style="width:100%;" v-else-if="item.sports_id == 16 " title="야구">
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[1].id" v-on:click="goCart(item, 1, $event)">{{ item.smp[1].odds }}</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate">VS</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[0].id" v-on:click="goCart(item, 0, $event)">{{ item.smp[0].odds }}</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate bg2" v-on:click="gameView(item)">+{{ item.cnt }}</b-col>
          </b-row>
          <b-row class="m-0 p-0" style="width:100%;" v-else-if="item.sports_id == 17" title="아이스하키">
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[2].id" v-on:click="goCart(item, 2, $event)">{{ item.smp[2].odds }}</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[1].id" v-on:click="goCart(item, 1, $event)">{{ item.smp[1].odds }}</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[0].id" v-on:click="goCart(item, 0, $event)">{{ item.smp[0].odds }}</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate bg2" v-on:click="gameView(item)">+{{ item.cnt }}</b-col>
          </b-row>
          <b-row class="m-0 p-0" style="width:100%;" v-else-if="item.sports_id == 18 && item.reserved == 0 " title="농구">
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[0].id" v-on:click="goCart(item, 0, $event)">{{ item.smp[0].odds }}</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate">VS</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[1].id" v-on:click="goCart(item, 1, $event)">{{ item.smp[1].odds }}</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate bg2" v-on:click="gameView(item)">+{{ item.cnt }}</b-col>
          </b-row>
          <b-row class="m-0 p-0" style="width:100%;" v-else-if="item.sports_id == 18 && item.reserved == 1 " title="농구">
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[1].id" v-on:click="goCart(item, 1, $event)">{{ item.smp[1].odds }}</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate">VS</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[0].id" v-on:click="goCart(item, 0, $event)">{{ item.smp[0].odds }}</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate bg2" v-on:click="gameView(item)">+{{ item.cnt }}</b-col>
          </b-row>
          <b-row class="m-0 p-0" style="width:100%;" v-else-if="item.sports_id == 91 " title="배구">
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[0].id" v-on:click="goCart(item, 0, $event)">{{ item.smp[0].odds }}</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate">VS</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[1].id" v-on:click="goCart(item, 1, $event)">{{ item.smp[1].odds }}</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate bg2" v-on:click="gameView(item)">+{{ item.cnt }}</b-col>
          </b-row>
          <b-row class="m-0 p-0" style="width:100%;" v-else-if="item.sports_id == 162 " title="MMA">
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[0].id" v-on:click="goCart(item, 0, $event)">{{ item.smp[0].odds }}</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate">VS</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate btn_rate" :class="'bet-id-'+item.smp[1].id" v-on:click="goCart(item, 1, $event)">{{ item.smp[1].odds }}</b-col>
            <b-col cols="3" class="ta-center p-0 odds-rate bg2" v-on:click="gameView(item)">+{{ item.cnt }}</b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>

import { BRow, BCol, BImg, BContainer, BButton, BPagination } from 'bootstrap-vue'
import EventBus from '../../event-bus';
import TabDefault from './TabDefault.vue'
import { getDay, getTime, makeLeagueImg, makeTeamImg } from '@core/utils/utils'

export default {
data () {
  return {
    datas: [],
    odds_name: 'Full Time Result',
    currentPage: 1,
    perPage: 50,
    rows: 0,
    sports_id: 0,
    users:[],
  }
},
components: {
  BRow, BCol, BImg, BContainer,BButton,TabDefault,BPagination
},
created () {
  EventBus.$on('add_bet', (id)=>
  {
    var bet_id = document.querySelector("#game-left-area .bet-id-"+id);
    if( bet_id != null)
    {
        bet_id.classList.add("selected");
    }
  });

  EventBus.$on('remove_bet', (id)=>
  {
    var bet_id = document.querySelector("#game-left-area .bet-id-"+id);
    if( bet_id != null)
    {
        bet_id.classList.remove("selected");
    }
  });

  EventBus.$on('next_page', ()=>
  {
    this.getDataAdd();
  });

},
mounted(){
  if( localStorage.getItem("userData") != null){
      this.users = JSON.parse(localStorage.getItem("userData"));
  }

  this.getData();
},
updated() {
  this.getBettingDataFromCookie();

  this.$nextTick(function () {
    // 모든 화면이 렌더링된 후 실행합니다.
  });
},
methods: {
  async getData (page=1, sports_id=this.sports_id ) {
    this.sports_id = sports_id;
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
      appLoading.style.display = 'block';
    }
    var results = await this.$api('http://server.honey-game.com/games/prematch/'+page+'/'+sports_id, 'get');
    this.datas = results.datas;
    this.rows = results.total_row;

    if( this.datas.length > 0 ){
      EventBus.$emit('game_view', this.datas[0]);
      EventBus.$emit('game_info', this.datas[0]);
    }else
    {
      EventBus.$emit('game_view', null);
      EventBus.$emit('game_info', null);
    }

    // 배당 갱신
    var results2 = await this.$api('http://server.honey-game.com/renew/renew_list/'+this.users.agent_idx, 'get');

    for( var i=0; i<results2.datas.length; i++ ){
      var bet_id = document.querySelector("#game-left-area .bet-id-"+results2.datas[i].rate_id);
      if( bet_id ){
        bet_id.innerHTML = (Math.round(results2.datas[i].rate_value * 100) / 100).toFixed(2);
      } 

      var bet_id2 = document.querySelector("#game-right-area .bet-id-"+results2.datas[i].rate_id+" .odds-value");
      if( bet_id2 ) {
        bet_id2.innerHTML = (Math.round(results2.datas[i].rate_value * 100) / 100).toFixed(2);
      }
    }

    appLoading.style.display = 'none';
  },
  async getDataAdd () {

    if( (this.rows / 100) <= this.currentPage ) return;

    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
      appLoading.style.display = 'block';
    }
    var results = await this.$api('http://server.honey-game.com/games/prematch/'+(++this.currentPage)+'/'+this.sports_id, 'get');
    this.datas = this.datas.concat(results.datas);

    // 배당 갱신
    var results2 = await this.$api('http://server.honey-game.com/renew/renew_list/'+this.users.agent_idx, 'get');

    for( var i=0; i<results2.datas.length; i++ ){
      var bet_id = document.querySelector("#game-left-area .bet-id-"+results2.datas[i].rate_id);
      if( bet_id ){
        bet_id.innerHTML = (Math.round(results2.datas[i].rate_value * 100) / 100).toFixed(2);
      } 

      var bet_id2 = document.querySelector("#game-right-area .bet-id-"+results2.datas[i].rate_id+" .odds-value");
      if( bet_id2 ) {
        bet_id2.innerHTML = (Math.round(results2.datas[i].rate_value * 100) / 100).toFixed(2);
      }
    }

    appLoading.style.display = 'none';
  },
  getBettingDataFromCookie(){
    if( localStorage.getItem("betData") != null){
      var betData = JSON.parse(localStorage.getItem("betData"));
      for(var value of betData)
      {
        var bet_id = document.querySelector("#game-left-area .bet-id-"+value.selected.id);
        if( bet_id != null)
        {
          if( !bet_id.classList.contains('selected') ){
            bet_id.classList.add("selected");
          }
        }
      }
    }
  },
  gameView(obj){
    EventBus.$emit('toggle_view', obj);
    EventBus.$emit('game_info', obj);
  },
  goCart(obj, j, event){
    var selected;
    selected = obj.smp[j];
    selected.odds = event.target.innerText;
    var bets = {
      'selected' : selected,
      'game_id' : obj.id,
      'league_cc' : obj.league_cc,
      'league' : obj.league,
      'league_code' :obj.league_code,
      'home' : obj.home_ko,
      'away' : obj.away_ko,
      'odds_name' : obj.odds_name,
      'odds_type' : obj.odds_type,
      'game_time' : obj.time,
      'sports_id' : obj.sports_id,
    }

    EventBus.$emit('update_cart', bets);
  },

  pageClick: function (button, page){
    this.getData(page);
    EventBus.$emit('upcoming-scroll-top');
  },

  getDay,
  getTime,
  makeLeagueImg,
  makeTeamImg,
},
beforeDestroy(){
  EventBus.$off();
},
}
</script>

<style>
#game-left-area {width:calc(100%-10px);background-color:#242a3a !important;padding:10px;}
#game-left-area .league-area { background-color:#233054;margin:0px;color:white;font-family: "Noto Sans KR",sans-serif;border: 1px solid #111;}
#game-left-area .league-area>div {line-height:28px;align-items:center;}
#game-left-area .coulum { display: flex; flex-direction: column; flex-shrink: 1; flex-basis: auto; flex-wrap: nowrap; justify-content: center;align-items: baseline !important; }
#game-left-area .coulum .team-name {margin-left:7px;}
#game-left-area .odds-rate {line-height:36px;background-color:#13161e;margin:0px;border:1px solid #383636;cursor:pointer;color:white;}
#game-left-area .odds-rate.bg2 {background-color:#081332;}
@media (hover: hover){
#game-left-area .odds-rate:hover { box-shadow: inset 0 0 0 1px #ffe588;transition: all .3s ease;}
}
#game-left-area .odds-rate.selected {box-shadow: inset 0 0 0 1px #ffe588;background-color:black !important;transition: all .3s ease;}
#game-left-area .game-list-data, .league-area {padding-top:0.2rem !important;padding-bottom:0.2rem !important;}
#game-left-area .game-list-data>div {display:flex;align-items: center;}
#game-left-area .center {justify-content: center;}
#game-left-area .sports_type { margin:2px;width:100px;padding:10px 0px;}

.b-pagination {justify-content:center;margin-top:10px;}

@media (max-width: 575px) {
#game-left-area {
  padding:0px !important;
  width:100% !important;
}
.app-content {
    padding: 0px !important;
    padding-top: 10px !important;
}
}

.team-name {
white-space: nowrap; 
text-overflow: ellipsis; 
display:inline-block;
}

.league-name {
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
}

.dark-layout .horizontal-layout .header-navbar{ display:none; }
.horizontal-layout.app-content {padding:10px 0px !important;}
.header-navbar-shadow {display:none;}

</style>