<template>
    <vue-perfect-scrollbar
          ref="Tabs"
          :settings="perfectScrollbarSettings"
          class="tabs-scroll-area"
          :always-show-scrollbar="true"
        >
    <b-tabs align="center" style="width:max-content;display:inline;" class="d-none d-md-inline">
      <b-tab active title="🏆 전체" @click="onTabChange(0)"></b-tab>
      <b-tab title="⚽ 축구" @click="onTabChange(1)"></b-tab>
      <b-tab title="⚾ 야구" @click="onTabChange(16)" v-if="users.use_baseball==1"></b-tab>
      <b-tab title="🏀 농구" @click="onTabChange(18)" v-if="users.use_basketball==1"></b-tab>
      <b-tab title="🏐 배구" @click="onTabChange(91)" v-if="users.use_volleyball==1"></b-tab>
      <b-tab title="🏒 아이스하키" @click="onTabChange(17)" v-if="users.use_hockey==1"></b-tab>
      <b-tab title="👊 MMA" @click="onTabChange(162)" v-if="users.use_mma==1"></b-tab>
    </b-tabs>

    <b-tabs align="center" style="width:max-content;display:inline;" class="d-inline d-md-none small-tab">
      <b-tab active title="🏆" @click="onTabChange(0)"></b-tab>
      <b-tab title="⚽" @click="onTabChange(1)"></b-tab>
      <b-tab title="⚾" @click="onTabChange(16)" v-if="users.use_baseball==1"></b-tab>
      <b-tab title="🏀" @click="onTabChange(18)" v-if="users.use_basketball==1"></b-tab>
      <b-tab title="🏐" @click="onTabChange(91)" v-if="users.use_volleyball==1"></b-tab>
      <b-tab title="🏒" @click="onTabChange(17)" v-if="users.use_hockey==1"></b-tab>
      <b-tab title="👊" @click="onTabChange(162)" v-if="users.use_mma==1"></b-tab>
    </b-tabs>

    </vue-perfect-scrollbar>

</template>

<script>
import { BTabs, BTab, BCardText } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  components: {
    BTabs,
    BCardText,
    BTab,
    VuePerfectScrollbar,
  },
  setup(){
    const perfectScrollbarSettings = {
      handlers : ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
      suppressScrollY : true,
      scrollingThreshold: 100000,
      wheelSpeed:1,
      swipeEasing:true,
    }
    return{
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      users : [],
    }
  },
  created() {
    if( localStorage.getItem("userData") != null){
      this.users = JSON.parse(localStorage.getItem("userData"));
      this.bet_money = 0;
    }
  },
  methods: {
    onTabChange (id) {
      this.$parent.getData(1, id);
    }
  }
}
</script>

<style>
.tabs-scroll-area {width:100%;height:45px;}
.small-tab li {font-size:18px;}
.nav-tabs .nav-link {padding: 0.61rem 0.6rem;}
</style>
